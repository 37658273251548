export const RTO_RISK_BUCKET_MAP = {
  'High Risk':  { name: 'High', priority: 2 },
  'Moderate Risk': { name: 'Moderate', priority: 1 },
  'No Risk': { name: 'No Risk', priority: 0 },
};

export const RTO_RISK_COLOR_CODE = {
  'High Risk':  'text-theme_1_amaranth',
  'Moderate Risk': 'text-theme_1_orange',
  'No Risk': 'text-theme_1_grey2',
};
