// @ts-nocheck
import { toast } from "react-toastify";
import isEmpty from "lodash.isempty";
import {
  exportOrdersReport as exportOrdersApi,
  getRTOProbabilityFAQs as getRTOProbabilityFAQsApi,
  getRTOProbablityReasons as getRTOProbablityReasonsApi,
  validateBulkOrderIdsSearch as validateBulkOrderIdsSearchApi,
  getReturnOrderReasons as getReturnOrderReasonsApi,
  getCancelReturnReasons as getCancelReturnReasonsApi,
  requestOrderReturn as requestOrderReturnApi,
  requestOrderReturnImages as requestOrderReturnImagesApi,
  exportFailedDeliveryOrderReport as exportFailedDeliveryOrderReportApi,
} from "data/orders";
import {
  exportNotSyncedOrdersReport,
  getFailedToSyncReasons as getFailedToSyncReasonsApi,
  validateNDRSheet as validateNDRSheetApi,
  submitBulkNDRSheet as submitBulkNDRSheetApi,
  getBulkNDRReports as getBulkNDRReportsApi,
  getNDRFlags as getNDRFlagsApi,
  markLatestReportVisited as markLatestReportVisitedApi,
} from 'data/shopify';
import { cancelReturnRequest as cancelReturnRequestApi } from 'data/shippers';
import { convertFileToFormData, downloadBlobFile, convertFilesToFormData } from 'utils/files';
import { RTO_RISK_BUCKET_MAP } from 'constants/rtoProbablity';
import { RTOProbablityFAQ, RTOProbablityReason } from 'types';
import { CountryCode, COUNTRY_CODE } from "constants/store";

export const exportOrdersReport = async (queryParams: any, file: File | string = '', onDownloadStart?: Function, onDownloadComplete?: Function) => {
  onDownloadStart?.();

  try {
    const fileBlob = await exportOrdersApi(queryParams, convertFileToFormData((file || ''), 'orderIdsSheet'));

    if (queryParams.isMasterDataDownload) { return; }

    downloadBlobFile(fileBlob, `${queryParams.status}-orders.xlsx`);

    toast.success('Orders exported successfully');
  } catch (err) {
    if (queryParams.isMasterDataDownload) { return; }

    toast.error(JSON.parse(await err?.response?.data?.text())?.status || 'Failed to export orders');
  } finally {
    onDownloadComplete?.();
  }
};

export const getRTOProbablityReasons = async (): Promise<Array<RTOProbablityReason>> => {
  try {
    const { rtoRiskBuckets = [], rtoProbabilityReasons = [] } = await getRTOProbablityReasonsApi();

    const data = [];

    if (rtoRiskBuckets?.length) {
      data.push(
        {
          id: 'rtoRiskBuckets',
          title: 'RTO Risk',
          options: rtoRiskBuckets.map((type: string) => ({
            id: type,
            // @ts-ignore
            reason: RTO_RISK_BUCKET_MAP[type]?.name || type,
          })),
        }
      );
    }

    if (rtoProbabilityReasons?.length) {
      data.push(
        {
          id: 'rtoProbabilityReasonIds',
          title: 'Reason',
          options: rtoProbabilityReasons,
        },
      )
    }

    return data;
  } catch {
    return [];
  }
};

export const getRTOProbabilityFAQs = async (): Promise<RTOProbablityFAQ | {}> => {
  try {
    const faqs = await getRTOProbabilityFAQsApi();
    return faqs;
  } catch {
    return {};
  }
};

export const validateBulkOrderIdsSearch = async (queryParams: any, orderIds: number[], onSuccess: Function, onFailure: Function) => {
  try {
    /** generating file from the csv */
    const csvFile = new File(["orderid\n" + orderIds.join("\n")], 'orderIds.csv', { type: 'text/csv' }); 

    const data = await validateBulkOrderIdsSearchApi(queryParams, convertFileToFormData(csvFile, 'orderIds'));

    const { orderIdType } = queryParams;
    const { validOrderIds = {} } = data || {};

    const cloutIds = (Object.keys(validOrderIds ?? {}) ?? []).map(Number);
    const shopifyIds = Object.values(validOrderIds ?? {}) ?? [];

    const reformedData = {
      orderIdType,
      invalidOrderIds: data?.inValidOrderIds ?? [],
      validOrderIds: orderIdType === 'shopify' ? shopifyIds : cloutIds,
      bulkSearchOrderIds: cloutIds,
    };

    onSuccess?.(reformedData);
  } catch (err) {
    onFailure?.();
    toast.error(err.response?.data?.status);
  }
};

export const exportFailedToSyncOrders = async (storesPrimaryKey: number, lastNDays: number, setLoading: Function, countryCode) => {
  setLoading(true);

  try {
    const fileBlob = await exportNotSyncedOrdersReport(storesPrimaryKey, lastNDays, countryCode);

    downloadBlobFile(fileBlob, `failed-to-sync-last-${lastNDays}-days-orders.xlsx`);

    toast.success('Orders exported successfully');
  } catch (err) {
    toast.error(JSON.parse(await err?.response?.data?.text())?.status || 'Failed to export orders');
  } finally {
    setLoading(false);
  }
};

const getPriceFormattedMarginOrderDetails = (marginDetails: any, aggregateMarginDetails: any) => {
  if (!marginDetails) return null;

  const cloutPrice = aggregateMarginDetails?.totalCloutPrice?.formattedPrice;
  const couponDiscount = aggregateMarginDetails?.totalCouponDiscount?.formattedPrice;
  const discountedCloutPrice = aggregateMarginDetails?.totalDiscountedCloutPrice?.formattedPrice;
  const margin = aggregateMarginDetails?.totalMargin?.formattedPrice;
  const sellingPrice = aggregateMarginDetails?.totalSellingPrice?.formattedPrice;
  const amountToPay = aggregateMarginDetails?.totalAmountToPay?.formattedPrice;
  const amountToPayValue = aggregateMarginDetails?.totalAmountToPay?.price;

  return {
    ...marginDetails,
    ...aggregateMarginDetails,
    cloutPrice,
    couponDiscount,
    discountedCloutPrice,
    margin,
    sellingPrice,
    amountToPay,
    amountToPayValue,
  }
}

export const mapManageOrdersDetails = (storeOrderDetails: any, countryCode: CountryCode = COUNTRY_CODE.india) => {
  return storeOrderDetails?.orderDetailsList?.map((order: any) => {
    return ({
      orderId: order.orderDetails.orderId,
      suborderProductDetailsList: [],
      showActions: Boolean(order.suborderDetailsList.length),
      subRows: order[order.resellSuborderDetailsList.length ? 'resellSuborderDetailsList' : 'suborderDetailsList'].map((suborder: any) => ({
        orderId: order.orderDetails.orderId,
        dropLocationId: order.dropLocationId,
        ivrResponse: order.ivrResponse,
        orderDateTime: order.orderDetails.orderDateTime,
        allowOnlinePayment: Boolean(order.allowOnlinePayment),
        saasChannelDetails: {
          saasChannel: order.saasChannel,
          saasOrderId: order.saasOrderId,
          saasOrderName: order.saasOrderName,
          channelStoreName: order.channelStoreName,
        },
        suborderProductDetails: {
          suborderId: suborder.suborderId,
          suborderProductName: suborder.suborderProductName,
          suborderQuantity: suborder.suborderQuantity,
          thumbnailUrl: suborder.thumbnailUrl,
          suborderModeOfPayment: suborder.suborderModeOfPayment,
          onlinePaymentStatus: suborder.onlinePaymentStatus,
          suborderPrice: suborder.suborderPriceData?.formattedPrice,
          skuId: suborder.skuId,
          shippingUnderProgress: suborder.shippingUnderProgress,
          labelDownLoaded: suborder.labelDownLoaded,
          suborderVariantDescription: suborder.suborderVariantDescription,
        },
        suborderProductDetailsList: [{
          suborderId: suborder.suborderId,
          suborderProductName: suborder.suborderProductName,
          suborderQuantity: suborder.suborderQuantity,
          thumbnailUrl: suborder.thumbnailUrl,
          suborderModeOfPayment: suborder.suborderModeOfPayment,
          onlinePaymentStatus: suborder.onlinePaymentStatus,
          suborderPrice: suborder.suborderPriceData?.formattedPrice,
          skuId: suborder.skuId,
          shippingUnderProgress: suborder.shippingUnderProgress,
          suborderVariantDescription: suborder.suborderVariantDescription,
        }],
        orderPaymentModeDetails: {
          orderPaymentMode: order.orderPaymentMode,
          orderPrice: order[(countryCode === COUNTRY_CODE.us || countryCode === COUNTRY_CODE.international) ? 'aggregateMarginDetailsUS' : 'aggregateMarginDetails']?.totalSellingPrice?.formattedPrice,
          dropshippingMarginDetails: 
            (countryCode === COUNTRY_CODE.us || countryCode === COUNTRY_CODE.international)
            ? getPriceFormattedMarginOrderDetails(suborder.dropshippingMarginDetailsUS, order.aggregateMarginDetailsUS) 
            : getPriceFormattedMarginOrderDetails(suborder.dropshippingMarginDetails, order.aggregateMarginDetails),
          dropshippingMarginDetailsIN: getPriceFormattedMarginOrderDetails(suborder.dropshippingMarginDetails, order.aggregateMarginDetails),
          dropshippingMarginDetailsUS: getPriceFormattedMarginOrderDetails(suborder.dropshippingMarginDetailsUS, order.aggregateMarginDetailsUS),
        },
        orderCustomerDetails: {
          orderCustomerName: order.orderDetails.orderCustomerName,
          orderCustomerPhone: order.orderDetails.orderCustomerPhone,
          orderCustomerEmail: order.orderDetails.orderCustomerEmail,
          orderCustomerAddress: order.orderDetails.orderCustomerAddress,
          orderCustomerCity: order.orderDetails.orderCustomerCity,
          orderCustomerPincode: order.orderDetails.orderCustomerPincode,
        },
        suborderRTODetails: {
          rtoRiskBucket: suborder.rtoRiskBucket || 'Calculating',
          rtoProbabilityReasons: suborder.rtoProbabilityReasons || [],
          orderPaymentMode: order.orderPaymentMode,
        },
        isAddressEdited: suborder.addressEdited || false,
        isOdaShipment: suborder.isOdaShipment,
        suborderStatus: suborder.suborderStatus,
        cancellationStage: suborder.cancellationStage,
        cancelledBy: suborder.cancelledBy,
        returnedBy: suborder.returnBy,
        awbStatus: suborder.awbStatus,
        shippingPartner: suborder.shippingPartner,
        trackingNumber: suborder.trackingNumber,
        trackingUrl: suborder.trackingUrl,
        reverseShippingPartner: suborder.reverseShippingPartner,
        reverseTrackingNumber: suborder.reverseTrackingNumber,
        reverseTrackingUrl: suborder.reverseTrackingUrl,
        suborderId: suborder.suborderId,
        deliveredDate: suborder.deliveredDate,
        returnEligible: suborder.returnEligible,
        cancelReturnEligible: suborder.cancelReturnEligible,
        lastReturnDate: suborder.lastReturnDate,
        returnType: suborder.returnType,
        orderDetails: {
          ...order.orderDetails,
          dropLocationId: order.dropLocationId,
          addressDetailsModalWarning: order.addressDetailsModalWarning,
        },
        ndrDetails: {
          ndrAttempt: suborder.ndrAttempt,
          ndrTimestamp: suborder.ndrTimeStamp,
          ndrReason: suborder.ndrReason,
          showNDRCta: suborder.showNDRCta,
          isMaxAttemptsReached: suborder.maxAttemptsReached,
          isRTOInitiated: suborder.rtoInitiated,
          isNDRUpdated: suborder.ndrUpdated,
          nextNDRAttempt: suborder.nextAttemptDate,
          firstFailedDeliveryDate: suborder.firstFailedDeliveryDate,
          bulkNdrOperartionInProgress: suborder.bulkNdrOperartionInProgress,
        },
        showOrderLevelActions: order.showOrderLevelActions,
        sellerShipmentWeight: order.sellerShipmentWeight,
        showActions: Boolean(order.suborderDetailsList.length),
        customerDetailsWarning: order.customerDetailsWarning,
        enteredDeadWeight: suborder.pickupWeight,
        enteredLength: suborder.dimensions && suborder.dimensions.split('-')[0],
        enteredBreadth: suborder.dimensions && suborder.dimensions.split('-')[1],
        enteredHeight: suborder.dimensions && suborder.dimensions.split('-')[2],
      }))
        .reduce((accumulator: any, currentValue: any) => {
          const newArray = [...accumulator];
          const newArrayIndex = newArray
            .findIndex((suborder) => ((suborder.suborderStatus === currentValue.suborderStatus)
              && (suborder.trackingNumber === currentValue.trackingNumber)));
          if (newArrayIndex !== -1) {
            const suborder = newArray[newArrayIndex];
            const suborderProductDetailsList = [...suborder.suborderProductDetailsList,
              {
                ...currentValue.suborderProductDetails,
              }];

            if (RTO_RISK_BUCKET_MAP[suborder.suborderRTODetails.rtoRiskBucket]?.priority < RTO_RISK_BUCKET_MAP[currentValue.suborderRTODetails.rtoRiskBucket]?.priority) {
              newArray[newArrayIndex].suborderRTODetails = currentValue.suborderRTODetails;
            }

            newArray[newArrayIndex].suborderProductDetailsList = suborderProductDetailsList;
          } else {
            newArray.push(currentValue);
          }
          return newArray;
        }, []),
    });
  });
}

export const getOrderFailedToSyncReasons = async (countryCode = COUNTRY_CODE.india) => {
  try {
    const reasons = await getFailedToSyncReasonsApi(countryCode);
    return reasons;
  } catch {
    return [];
  }
};

export const getReturnOrderReasons = async () => {
  try {
    const { listElements: reasons = [] } = await getReturnOrderReasonsApi();
    return (reasons || []).filter(reason => reason?.returnableOnClout); // filter out reasons that are valid for products on clout
  } catch (err) {
    toast.error(err?.response?.data?.status);
    return [];
  }
};

export const getCancelReturnReasons = async () => {
  try {
    const reasons = await getCancelReturnReasonsApi();
    return reasons.rvpCancellationReasons;
  } catch (err) {
    toast.error(err?.response?.data?.status);
    return [];
  }
};

export const requestOrderReturn = async (suborderId: string | number, data: any, onSuccess: Function, onFailure: Function) => {
  try {
    const { realImages, ...payload } = data;

    if (!isEmpty(realImages)) {
      const imagesPayload = convertFilesToFormData(Object.values(realImages), 'realImage');
      await Promise.all([ requestOrderReturnApi(suborderId, payload), requestOrderReturnImagesApi(suborderId, imagesPayload) ]);
    } else {
      await requestOrderReturnApi(suborderId, payload);
    }

    toast.success('Return request placed successfully and order moved to “Closed > Returned” tab.');
    onSuccess?.();
  } catch (err) {
    console.log(err);
    toast.error('Could not place return request, please try again');
    onFailure?.();
  }
};

export const requestReturnCancellation = async (suborderId: string | number, data: any, onSuccess: Function, onFailure: Function) => {
  try {
    await cancelReturnRequestApi(suborderId, data);
    toast.success('Return request cancelled successfully and order moved to “Closed > Delivered” tab.');
    onSuccess?.();
  } catch (err) {
    console.log(err);
    toast.error('Could not cancel return request, please try again');
    onFailure?.();
  }
};

export const exportNDRFailedDeliveryOrders = async (params: any, setLoading: Function) => {
  try {
    setLoading(true);
    const fileBlob = await exportFailedDeliveryOrderReportApi(params, convertFileToFormData('', 'orderIdsSheet'));
    downloadBlobFile(fileBlob, `failed-delivery-orders.xlsx`);
    toast.success('Orders exported successfully');
  } catch (err) {
    toast.error(JSON.parse(await err?.response?.data?.text())?.status || 'Failed to export orders');
  } finally {
    setLoading(false);
  }
};

export const validateNDRSheet = async (storesPrimaryKey: string | number, imageData: any, setIsUploading: Function, setUploadState: Function) => {
  setIsUploading(true);
  try {
    const data = await validateNDRSheetApi(storesPrimaryKey, convertFileToFormData(imageData, 'ndrOperationSheet'));
    setUploadState({ validated: !data?.errors?.length, errors: data?.errors || [] });
  } catch (err) {
    toast.error(err?.response?.data?.status);
  } finally {
    setIsUploading(false);
  }
};

export const submitBulkNDRSheet = async (storesPrimaryKey: string | number, imageData: any, setIsSubmitting: Function, onSuccess: Function) => {
  setIsSubmitting(true);
  try {
    await submitBulkNDRSheetApi(storesPrimaryKey, convertFileToFormData(imageData, 'ndrOperationSheet'));
    onSuccess?.();
  } catch (err) {
    toast.error(err?.response?.data?.status);
  } finally {
    setIsSubmitting(false);
  }
};

export const getBulkNDRReports = async (storesPrimaryKey: string | number, setLoading: Function, setReportData: Function, onSuccess: Function) => {
  setLoading(true);
  try {
    const data = await getBulkNDRReportsApi(storesPrimaryKey);
    setReportData(data);
    onSuccess?.();
  } catch (err) {
    toast.error(err?.response?.data?.status);
  } finally {
    setLoading(false);
  }
};

export const getNDRFlags =  async (storesPrimaryKey: string | number, setNDRFlags) => {
  try {
    const data = await getNDRFlagsApi(storesPrimaryKey);
    setNDRFlags(data);
  } catch (err) {
    toast.error(err?.response?.data?.status);
    console.log(err);
  }
};

export const markLatestReportVisited =  async (storesPrimaryKey: string | number, onSuccess: Function) => {
  try {
    await markLatestReportVisitedApi(storesPrimaryKey);
    onSuccess?.();
  } catch (err) {
    toast.error(err?.response?.data?.status);
    console.log(err);
  }
};
