/** convert a blob object to file type */
export const convertBlobToFile = (blob: Blob, identifier: string): File => {
  const { type } = blob;
  const fileName = `${identifier}.${type.split("/")?.[1] || "png"}`;

  const file = new File([blob], fileName, { type });
  return file;
};

export const convertFileToFormData = (
  file: File | string,
  identifier: string
) => {
  const formData = new FormData();
  formData.append(identifier, file);

  return formData;
};

export const convertFilesToFormData = (
  files: Array<File | string>,
  identifier: string
) => {
  const formData = new FormData();

  (files || []).forEach((file) => {
    formData.append(identifier, file);
  });

  return formData;
};

/** download file received in api blob response */
export const downloadBlobFile = (fileBlob: Blob, fileName: string): void => {
  const url = window.URL.createObjectURL(fileBlob);
  const fileLink = document.createElement("a");

  fileLink.setAttribute("href", url);
  fileLink.setAttribute("download", fileName);

  document.body.appendChild(fileLink);
  fileLink.click();
  document.body.removeChild(fileLink);
};

const createImage = (url: string) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
  });
};

export const getCroppedImage = async (
  imageSrc: any,
  pixelCrop: any,
  dimensions: any
) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx: any = canvas.getContext("2d");

  // Force the canvas size to specified dimension for the final cropped output
  const { width: targetWidth, height: targetHeight } = dimensions;

  canvas.width = targetWidth;
  canvas.height = targetHeight;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    targetWidth,
    targetHeight
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error("Canvas is empty"));
        return;
      }

      const file = new File([blob], "cropped_image.jpg", {
        type: "image/jpeg",
      });

      resolve(file);
    }, "image/jpeg");
  });
};
