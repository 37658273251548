import { COUNTRY_CODE } from "constants/store";
import {apiCall, paramsToString} from "utils/api";
import {sellerStoreId} from 'utils/auth';
import { convertFileToFormData } from "utils/files";

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/orders`;

export const getOrders = async (status: string[] | string, offset = 0, limit = 10, paginationKey = 0, storesPrimaryKey = 0) => {

  const response = await apiCall({
    method: 'get',
    headers: {
      'business-type': 'shopifyDropshippingPlugin',
      'content-type': 'application/json'
    },
    url: `${HOST}/v2/details?${paramsToString({
      storeId: sellerStoreId(),
      status,
      limit,
      offset,
      paginationKey,
      storesPrimaryKey,
    })}`,
  })
  return response.data;
}

export const searchStoreOrders = async (
  status: string[] | string, 
  offset = 0, 
  limit = 10, 
  paginationKey = 0,
  storesPrimaryKey = 0,
  searchBy: { [key: string]: string | number},
  dateFilterQuery: { [key: string]: number},
  rtoFilterQuery: { [key: string]: Array<string | number>},
  file = '',
  slaFilter: { [key: string]: string },
  ndrFilter: {
    ndr?: boolean;
    ndrAction?: boolean;
    ndrDate?: string;
  } = {},
  countryCode=COUNTRY_CODE.india,
) => {
  try {
    const response = await apiCall({
      method: 'post',
      url: `${HOST}/stores/${sellerStoreId()}/details/v2?${paramsToString({
        storeId: sellerStoreId(),
        countryCode,
        status,
        limit,
        offset,
        paginationKey,
        storesPrimaryKey,
        ...searchBy,
        ...dateFilterQuery,
        ...rtoFilterQuery,
        ...slaFilter,
        ...(ndrFilter?.ndr ? ndrFilter : {}),
      })}`,
      data: convertFileToFormData((file || ''), 'orderIdsSheet')
    })

    return response.data;
  } catch (err) {
    return { orderDetailsList: [] };
  }
}

export const updateBuyerAddress = async (orderId: number, userAddress = '', userCity = '', userMobileNumber = '', userName = '',
                                         userPincode = '', userAlternateMobileNumber = '', email: any, shouldUpdateAddress?: boolean) => {

  const data: any = {};

  if (userCity) {
    data.userCity = userCity;
  }
  if (email || email === '') {
    data.userEmail = email;
  }
  if (userMobileNumber) {
    data.userMobileNumber = userMobileNumber;
  }
  if (userName) {
    data.userName = userName;
  }
  if (userPincode) {
    data.userPincode = userPincode;
  }
  if (userAlternateMobileNumber) {
    data.userAlternateMobileNumber = userAlternateMobileNumber;
  }
  if (userAddress && shouldUpdateAddress) {
    data.userAddress = userAddress;
  }

  const response = await apiCall({
    method: 'put',
    headers: {
      'business-type': 'shopifyDropshippingPlugin',
      'content-type': 'application/json'
    },
    url: `${HOST}/${orderId}/stores/${sellerStoreId()}/buyerAddress?updateBuyerAddr=${shouldUpdateAddress}`,
    data,
  })
  return response.data;
}

export const exportOrdersReport = async (queryParams: { [key: string]: any }, data: any) => {
  const response = await apiCall({
    method: 'post',
    responseType: 'blob',
    url: `${HOST}/stores/${sellerStoreId()}/downloadDetails?${paramsToString({ 
      ...queryParams, 
      countryCode: queryParams?.countryCode || COUNTRY_CODE.india,
    })}`,
    data,
  });

  return response.data;
};

export const getRTOProbabilityFAQs = async () => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/rtoProbabilityFAQ`,
  })
  return res.data;
};

export const getRTOProbablityReasons = async () => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/rtoProbabilityReasons`,
  })
  return res.data;
};

export const validateBulkOrderIdsSearch = async (queryParams: any, data: any) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/stores/${sellerStoreId()}/validOrdersFilter?${paramsToString(queryParams)}`,
    data,
  });

  return res.data;
};

export const getReturnOrderReasons = async () => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/clout/returnReason`,
  });

  return res.data;
};

export const getCancelReturnReasons = async () => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/rvpcancellation/reasons`,
  });

  return res.data;
};

export const requestOrderReturn = async (suborderId: string | number, data: any) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/suborders/${suborderId}/reversePickup`,
    data,
  });

  return res.data;
};

export const requestOrderReturnImages = async (suborderId: string | number, data: FormData) => {
  const res = await apiCall({
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data; boundary=--returnRequestImageFileBoundary',
    },
    url: `${HOST}/suborders/${suborderId}/reversePickupImages`,
    data,
  });

  return res.data;
};

export const getOrderSummary = async (queryParams: { [key: string]: any }) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/stores/${sellerStoreId()}/summaryView?${paramsToString({ countryCode: COUNTRY_CODE.india, ...queryParams })}`,
  });

  return res.data;
}

export const exportFailedDeliveryOrderReport = async (queryParams: any, data: any) => {
  const res = await apiCall({
    method: 'post',
    responseType: 'blob',
    url: `${HOST}/stores/${sellerStoreId()}/details/v2?${paramsToString({ storeId: sellerStoreId(), countryCode: COUNTRY_CODE.india, ...queryParams })}`,
    data
  });

  return res.data;
};
