import {apiCall} from "utils/api";
import {sellerStoreId} from "utils/auth";

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/shippers`;

export const getCityUsingPincode = async (pincode: string) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/pincode/${pincode}`,
  })
  return res.data;
};

export const cancelReturnRequest = async (suborderId: string | number, data: any) => {
  const res = await apiCall({
    method: 'put',
    url: `${HOST}/stores/${sellerStoreId()}/suborders/${suborderId}/cancelReversePickup`,
    data,
  });

  return res.data;
};
